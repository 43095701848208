// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IOW00mDFAtWSDZ6dkzlQ {\n    display: block;\n    overflow-x: scroll;\n}\n\n@media (min-width: 600px) {\n    .IOW00mDFAtWSDZ6dkzlQ {\n        display: table;\n    }\n}\n\n.SZS2yG05KhE1BtScIO2j {\n    color: var(--colors-grey700);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Versions/VersionsTable/VersionsTable.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".table {\n    display: block;\n    overflow-x: scroll;\n}\n\n@media (min-width: 600px) {\n    .table {\n        display: table;\n    }\n}\n\n.channelNameCell {\n    color: var(--colors-grey700);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "IOW00mDFAtWSDZ6dkzlQ",
	"channelNameCell": "SZS2yG05KhE1BtScIO2j"
};
export default ___CSS_LOADER_EXPORT___;
