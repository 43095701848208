// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IO2BBb_bFO9Yqyw0qgFK {\n    display: block !important;\n    max-width: 680px;\n    margin: auto;\n    padding: var(--spacers-dp16);\n}\n\n.PIzPMUu_Sro29MoaYtNB {\n    font-size: 26px;\n    font-weight: 400;\n    color: var(--colors-grey800);\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp16);\n}\n\n.gN4njfooG5RyLBZOlRgi {\n    font-size: 14px;\n    color: var(--colors-grey700);\n}\n\n.BzUI6wH5UcrvWJFzljV0 {\n    display: flex;\n    align-items: center;\n}\n\n.L3oUrH4qTbp04ro1WqYv {\n    justify-content: space-between;\n}\n\n.vfaHZm4MQf1Oei385POw {\n    margin-top: var(--spacers-dp24);\n    margin-bottom: var(--spacers-dp24);\n}\n\n.x1_QqlCkBfA9heykzA8J {\n    margin-left: var(--spacers-dp4);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UserApiKey/UserApiKey.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,4BAA4B;IAC5B,aAAa;IACb,kCAAkC;AACtC;;AAEA;IACI,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,+BAA+B;IAC/B,kCAAkC;AACtC;;AAEA;IACI,+BAA+B;AACnC","sourcesContent":[".card {\n    display: block !important;\n    max-width: 680px;\n    margin: auto;\n    padding: var(--spacers-dp16);\n}\n\n.header {\n    font-size: 26px;\n    font-weight: 400;\n    color: var(--colors-grey800);\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp16);\n}\n\n.description {\n    font-size: 14px;\n    color: var(--colors-grey700);\n}\n\n.flexCenter {\n    display: flex;\n    align-items: center;\n}\n\n.justifyBetween {\n    justify-content: space-between;\n}\n\n.noticeBox {\n    margin-top: var(--spacers-dp24);\n    margin-bottom: var(--spacers-dp24);\n}\n\n.copyToClipboardButton {\n    margin-left: var(--spacers-dp4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "IO2BBb_bFO9Yqyw0qgFK",
	"header": "PIzPMUu_Sro29MoaYtNB",
	"description": "gN4njfooG5RyLBZOlRgi",
	"flexCenter": "BzUI6wH5UcrvWJFzljV0",
	"justifyBetween": "L3oUrH4qTbp04ro1WqYv",
	"noticeBox": "vfaHZm4MQf1Oei385POw",
	"copyToClipboardButton": "x1_QqlCkBfA9heykzA8J"
};
export default ___CSS_LOADER_EXPORT___;
