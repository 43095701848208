// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._kMEsMelD01zjhAVNDfH {\n    display: block !important;\n    max-width: 960px;\n    margin: auto;\n    padding: var(--spacers-dp16);\n}\n\n._xPg5FYVLn4iWJKgrm5d {\n    font-size: 26px;\n    font-weight: 400;\n    color: var(--colors-grey800);\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp16);\n}\n\n.tOe6Xbeg3uGttNR9iIb9 {\n    margin-bottom: var(--spacers-dp24);\n    max-width: 480px;\n}\n\n.eMW_nvJqxKeuwDIpd3B1 {\n    text-decoration: underline;\n}\n\n.eMW_nvJqxKeuwDIpd3B1:hover, .eMW_nvJqxKeuwDIpd3B1:focus {\n    color: var(--colors-grey900);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UserAppVersionNew/UserAppVersionNew.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,4BAA4B;IAC5B,aAAa;IACb,kCAAkC;AACtC;;AAEA;IACI,kCAAkC;IAClC,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".card {\n    display: block !important;\n    max-width: 960px;\n    margin: auto;\n    padding: var(--spacers-dp16);\n}\n\n.header {\n    font-size: 26px;\n    font-weight: 400;\n    color: var(--colors-grey800);\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp16);\n}\n\n.field {\n    margin-bottom: var(--spacers-dp24);\n    max-width: 480px;\n}\n\n.helpTextLink {\n    text-decoration: underline;\n}\n\n.helpTextLink:hover, .helpTextLink:focus {\n    color: var(--colors-grey900);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "_kMEsMelD01zjhAVNDfH",
	"header": "_xPg5FYVLn4iWJKgrm5d",
	"field": "tOe6Xbeg3uGttNR9iIb9",
	"helpTextLink": "eMW_nvJqxKeuwDIpd3B1"
};
export default ___CSS_LOADER_EXPORT___;
