// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K0Dvhk4TEV0M3HDgFplQ {\n    margin: var(--spacers-dp16) 0;\n}\n\n.zMHAxvGiHkJnN1BiaCUb {\n    display: flex;\n    align-items: center;\n    margin-bottom: var(--spacers-dp16);\n}\n\n.lxb7nrPvdoVViO8HmmnP {\n    margin-right: var(--spacers-dp8);\n}\n\n.zdmjkpX2N_cEXoz8ei3c {\n    text-decoration: underline;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Apps/SidebarInfo/SidebarInfo.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kCAAkC;AACtC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".container {\n    margin: var(--spacers-dp16) 0;\n}\n\n.entry {\n    display: flex;\n    align-items: center;\n    margin-bottom: var(--spacers-dp16);\n}\n\n.icon {\n    margin-right: var(--spacers-dp8);\n}\n\n.link {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "K0Dvhk4TEV0M3HDgFplQ",
	"entry": "zMHAxvGiHkJnN1BiaCUb",
	"icon": "lxb7nrPvdoVViO8HmmnP",
	"link": "zdmjkpX2N_cEXoz8ei3c"
};
export default ___CSS_LOADER_EXPORT___;
