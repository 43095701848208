// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".i8e37bZHMVr7zrrNHbIy {\n    font-size: 14px;\n    font-weight: 500;\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp8);\n}\n\n._sMLKTWYfqh_9p4uQAgI {\n    padding: var(--spacers-dp16) var(--spacers-dp8);\n}\n\n.bI7_x3JNmjUOogJ4bLYd {\n    margin-bottom: var(--spacers-dp16);\n}\n\n.YF5d_aFRoXZtyYWFjuMo {\n    max-width: 200px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Versions/Filters/Filters.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,iCAAiC;AACrC;;AAEA;IACI,+CAA+C;AACnD;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".subheader {\n    font-size: 14px;\n    font-weight: 500;\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp8);\n}\n\n.versionsFilters {\n    padding: var(--spacers-dp16) var(--spacers-dp8);\n}\n\n.channelsFilter {\n    margin-bottom: var(--spacers-dp16);\n}\n\n.dhisVersionSelect {\n    max-width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subheader": "i8e37bZHMVr7zrrNHbIy",
	"versionsFilters": "_sMLKTWYfqh_9p4uQAgI",
	"channelsFilter": "bI7_x3JNmjUOogJ4bLYd",
	"dhisVersionSelect": "YF5d_aFRoXZtyYWFjuMo"
};
export default ___CSS_LOADER_EXPORT___;
