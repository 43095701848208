// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UUVG8YC_Y99Kec9KJUCb {\n    display: block;\n    width: 100%;\n    border: none;\n    text-align: start;\n    cursor: pointer;\n    user-select: none;\n    font-size: 14px;\n    line-height: 16px;\n    padding: var(--spacers-dp8) var(--spacers-dp12) 6px;\n    background: white;\n}\n\n.UUVG8YC_Y99Kec9KJUCb:hover {\n    text-decoration: none;\n}\n\n.UUVG8YC_Y99Kec9KJUCb:focus {\n    outline: none;\n}\n\n.UUVG8YC_Y99Kec9KJUCb:hover, .UUVG8YC_Y99Kec9KJUCb:focus-visible {\n    background-color: var(--colors-grey300);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/DropdownMenuItem/DropdownMenuItem.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,mDAAmD;IACnD,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,uCAAuC;AAC3C","sourcesContent":[".item {\n    display: block;\n    width: 100%;\n    border: none;\n    text-align: start;\n    cursor: pointer;\n    user-select: none;\n    font-size: 14px;\n    line-height: 16px;\n    padding: var(--spacers-dp8) var(--spacers-dp12) 6px;\n    background: white;\n}\n\n.item:hover {\n    text-decoration: none;\n}\n\n.item:focus {\n    outline: none;\n}\n\n.item:hover, .item:focus-visible {\n    background-color: var(--colors-grey300);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "UUVG8YC_Y99Kec9KJUCb"
};
export default ___CSS_LOADER_EXPORT___;
