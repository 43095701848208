// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qWZjzJQZtF2FBmAnitDh {\n    display: block !important;\n    max-width: 480px;\n    margin: auto;\n    padding: var(--spacers-dp24);\n    text-align: center;\n}\n\n.rUDv5SUm4ytufg_8j6tW {\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp24);\n}\n\n._hAFve_FYhtd7rwUuMvL {\n    display: block;\n    margin: var(--spacers-dp8) 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/OrganisationInvitation/OrganisationInvitation.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,4BAA4B;IAC5B,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kCAAkC;AACtC;;AAEA;IACI,cAAc;IACd,4BAA4B;AAChC","sourcesContent":[".card {\n    display: block !important;\n    max-width: 480px;\n    margin: auto;\n    padding: var(--spacers-dp24);\n    text-align: center;\n}\n\n.explanation {\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp24);\n}\n\n.middle {\n    display: block;\n    margin: var(--spacers-dp8) 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "qWZjzJQZtF2FBmAnitDh",
	"explanation": "rUDv5SUm4ytufg_8j6tW",
	"middle": "_hAFve_FYhtd7rwUuMvL"
};
export default ___CSS_LOADER_EXPORT___;
