// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OEhJjJw9A51oM8w9CVfW {\n    font-size: 16px;\n    font-weight: 500;\n    color: var(--colors-grey800);\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp4);\n}\n\n.eJRNcvnvreH1ooDQD8tE {\n    margin: 0;\n}\n\n.rZc4gMnhTbW4Y5hlXwQp {\n    color: var(--colors-blue700);\n    text-decoration: underline;\n}\n\n.rZc4gMnhTbW4Y5hlXwQp:hover, .rZc4gMnhTbW4Y5hlXwQp:focus {\n    color: var(--colors-grey900);\n}\n\n.jyYVbUUvIsjKJwe4swEv {\n    display: none;\n}\n\n.xh8lAiKJN1koxI4Oy9vq {\n    margin-top: var(--spacers-dp16);\n}\n\n._hWreHnOuyPpqzsy_Avr {\n    margin-top: var(--spacers-dp8);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UserApp/ScreenshotsCard/ScreenshotsCard.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,4BAA4B;IAC5B,aAAa;IACb,iCAAiC;AACrC;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,4BAA4B;IAC5B,0BAA0B;AAC9B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".addScreenshotHeader {\n    font-size: 16px;\n    font-weight: 500;\n    color: var(--colors-grey800);\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp4);\n}\n\n.addScreenshotDescription {\n    margin: 0;\n}\n\n.screenshotsGuidelinesLink {\n    color: var(--colors-blue700);\n    text-decoration: underline;\n}\n\n.screenshotsGuidelinesLink:hover, .screenshotsGuidelinesLink:focus {\n    color: var(--colors-grey900);\n}\n\n.hidden {\n    display: none;\n}\n\n.uploadButton {\n    margin-top: var(--spacers-dp16);\n}\n\n.deleteButton {\n    margin-top: var(--spacers-dp8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addScreenshotHeader": "OEhJjJw9A51oM8w9CVfW",
	"addScreenshotDescription": "eJRNcvnvreH1ooDQD8tE",
	"screenshotsGuidelinesLink": "rZc4gMnhTbW4Y5hlXwQp",
	"hidden": "jyYVbUUvIsjKJwe4swEv",
	"uploadButton": "xh8lAiKJN1koxI4Oy9vq",
	"deleteButton": "_hWreHnOuyPpqzsy_Avr"
};
export default ___CSS_LOADER_EXPORT___;
