// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/breakpoints.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9SfdPBjNmgSFMgnrgYL {\n    margin: var(--spacers-dp16) 0;\n}\n\n._uxytA0BBlvNhdzM16Nb {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["m-medium"] + " {\n    ._uxytA0BBlvNhdzM16Nb {\n        grid-template-columns: 1fr;\n    }\n}\n\n._A_MSZLciJbkS_dKP8m1 {\n    margin-bottom: 1.5rem;\n}\n\n.xBzWZo_8P6LbGplgULvm {\n    margin-top: 0;\n    margin-bottom: 0.3em;\n    font-size: 14px;\n    font-weight: 500;\n}\n\n.L4cJnFqFGajfOBWcihH2 {\n    margin: 0;\n    max-width: 180px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Apps/Filters/Filters.module.css"],"names":[],"mappings":"AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI;QACI,0BAA0B;IAC9B;AACJ;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,SAAS;IACT,gBAAgB;AACpB","sourcesContent":["@value m-medium from 'src/styles/breakpoints.css';\n\n.dividerContainer {\n    margin: var(--spacers-dp16) 0;\n}\n\n.filters {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n}\n\n@media m-medium {\n    .filters {\n        grid-template-columns: 1fr;\n    }\n}\n\n.filterWrapper {\n    margin-bottom: 1.5rem;\n}\n\n.filterName {\n    margin-top: 0;\n    margin-bottom: 0.3em;\n    font-size: 14px;\n    font-weight: 500;\n}\n\n.dhisVersionSelect {\n    margin: 0;\n    max-width: 180px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"m-medium": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["m-medium"] + "",
	"dividerContainer": "_9SfdPBjNmgSFMgnrgYL",
	"filters": "_uxytA0BBlvNhdzM16Nb",
	"filterWrapper": "_A_MSZLciJbkS_dKP8m1",
	"filterName": "xBzWZo_8P6LbGplgULvm",
	"dhisVersionSelect": "L4cJnFqFGajfOBWcihH2"
};
export default ___CSS_LOADER_EXPORT___;
