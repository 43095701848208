// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wT_uFemVsaIBPJU2OLnk h1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n    font-size: 16px;\n    font-weight: 500;\n}\n\n.wT_uFemVsaIBPJU2OLnk a {\n    color: var(--colors-blue600);\n}\n\n.wT_uFemVsaIBPJU2OLnk a:hover,\n.wT_uFemVsaIBPJU2OLnk a:focus {\n    color: var(--colors-blue700);\n    text-decoration: underline;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AppDescription/AppDescription.module.css"],"names":[],"mappings":"AAAA;;;;;;IAMI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;;IAEI,4BAA4B;IAC5B,0BAA0B;AAC9B","sourcesContent":[".markdownDescription h1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n    font-size: 16px;\n    font-weight: 500;\n}\n\n.markdownDescription a {\n    color: var(--colors-blue600);\n}\n\n.markdownDescription a:hover,\n.markdownDescription a:focus {\n    color: var(--colors-blue700);\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"markdownDescription": "wT_uFemVsaIBPJU2OLnk"
};
export default ___CSS_LOADER_EXPORT___;
