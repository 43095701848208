// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._NFw5P90mV0Evjf49g0E {\n    display: block !important;\n    max-width: 960px;\n    margin: auto;\n    padding: var(--spacers-dp16);\n}\n\n._R2xYXTo9ZBO10m8sBn7 {\n    font-size: 26px;\n    font-weight: 400;\n    color: var(--colors-grey800);\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp16);\n}\n\n.W5UBluvVy0Y2ObM4HAc6 {\n    margin-bottom: var(--spacers-dp24);\n    max-width: 480px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UserOrganisationNew/UserOrganisationNew.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,4BAA4B;IAC5B,aAAa;IACb,kCAAkC;AACtC;;AAEA;IACI,kCAAkC;IAClC,gBAAgB;AACpB","sourcesContent":[".card {\n    display: block !important;\n    max-width: 960px;\n    margin: auto;\n    padding: var(--spacers-dp16);\n}\n\n.header {\n    font-size: 26px;\n    font-weight: 400;\n    color: var(--colors-grey800);\n    margin-top: 0;\n    margin-bottom: var(--spacers-dp16);\n}\n\n.field {\n    margin-bottom: var(--spacers-dp24);\n    max-width: 480px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "_NFw5P90mV0Evjf49g0E",
	"header": "_R2xYXTo9ZBO10m8sBn7",
	"field": "W5UBluvVy0Y2ObM4HAc6"
};
export default ___CSS_LOADER_EXPORT___;
