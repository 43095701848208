// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rloZ3_lHC6NPTaH89uKp {\n    border: 1px solid var(--colors-grey300);\n}\n\n.PIXT__77X23fsNkjg9F6 {\n    display: inline-block;\n    padding: var(--spacers-dp8);\n    padding-top: 0;\n}\n\n.EYfth58cLG_EiZdfLOOQ {\n    display: flex;\n    justify-content: center;\n    margin: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Versions/Versions.module.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;AAC3C;;AAEA;IACI,qBAAqB;IACrB,2BAA2B;IAC3B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;AACf","sourcesContent":[".versionsContainer {\n    border: 1px solid var(--colors-grey300);\n}\n\n.noVersions {\n    display: inline-block;\n    padding: var(--spacers-dp8);\n    padding-top: 0;\n}\n\n.loadMore {\n    display: flex;\n    justify-content: center;\n    margin: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"versionsContainer": "rloZ3_lHC6NPTaH89uKp",
	"noVersions": "PIXT__77X23fsNkjg9F6",
	"loadMore": "EYfth58cLG_EiZdfLOOQ"
};
export default ___CSS_LOADER_EXPORT___;
