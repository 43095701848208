// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wiYIxP8cdm_LNX3gvKrH {\n    margin-left: var(--spacers-dp8);\n}\n\n.Hpmj5xAmkda0eJMPh9qq {\n    display: inline-block;\n    margin-top: var(--spacers-dp12);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UserApp/VersionsCard/VersionsCard.module.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;AACnC;;AAEA;IACI,qBAAqB;IACrB,+BAA+B;AACnC","sourcesContent":[".deleteVersionButton {\n    margin-left: var(--spacers-dp8);\n}\n\n.newVersionButton {\n    display: inline-block;\n    margin-top: var(--spacers-dp12);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteVersionButton": "wiYIxP8cdm_LNX3gvKrH",
	"newVersionButton": "Hpmj5xAmkda0eJMPh9qq"
};
export default ___CSS_LOADER_EXPORT___;
