// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I20Oe2M8j2Jr6PVrisxo {\n    background: rgb(39, 102, 150) !important;\n}\n\n._ErfVP1WWqjEhJOrgHlJ._ErfVP1WWqjEhJOrgHlJ.wOL54EVNrPD9AmH8TSQj .I20Oe2M8j2Jr6PVrisxo ._Dl4RLdGBhdclCl93rPx {\n    opacity: 0 !important;\n}\n\n._ErfVP1WWqjEhJOrgHlJ._ErfVP1WWqjEhJOrgHlJ .U9iOVAir636lhuYSLMnx {\n    color: #ffffff !important;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/auth0-overrides.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;AAC5C;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".auth0-lock-header-bg {\n    background: rgb(39, 102, 150) !important;\n}\n\n.auth0-lock.auth0-lock.auth0-lock-opened .auth0-lock-header-bg .auth0-lock-header-bg-blur {\n    opacity: 0 !important;\n}\n\n.auth0-lock.auth0-lock .auth0-lock-header {\n    color: #ffffff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auth0-lock-header-bg": "I20Oe2M8j2Jr6PVrisxo",
	"auth0-lock": "_ErfVP1WWqjEhJOrgHlJ",
	"auth0-lock-opened": "wOL54EVNrPD9AmH8TSQj",
	"auth0-lock-header-bg-blur": "_Dl4RLdGBhdclCl93rPx",
	"auth0-lock-header": "U9iOVAir636lhuYSLMnx"
};
export default ___CSS_LOADER_EXPORT___;
