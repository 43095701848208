// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".seXSJk_1kWYVZ4eAnISL {\n    display: grid;\n    grid-template-columns: 1fr auto;\n    margin-bottom: var(--spacers-dp24);\n    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);\n    padding: var(--spacers-dp16);\n}\n\n.gbDXqyDMyHWmo8j2w6f4 {\n    margin-bottom: var(--spacers-dp8);\n}\n\n._5yVceQNohxZjqVok_em {\n    display: inline-block;\n    font-size: 16px;\n    font-weight: 500;\n    margin: 0;\n    margin-right: var(--spacers-dp8);\n}\n\n.bbN0HIE0_vdU3Pm4XNTF {\n    color: var(--colors-grey800);\n}\n\n._3d7ANDGo9QPzxCQN1H_ {\n    margin-left: var(--spacers-dp8);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/UserOrganisation/OrganisationUser/OrganisationUser.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,+BAA+B;IAC/B,kCAAkC;IAClC,yCAAyC;IACzC,4BAA4B;AAChC;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,gCAAgC;AACpC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,+BAA+B;AACnC","sourcesContent":[".container {\n    display: grid;\n    grid-template-columns: 1fr auto;\n    margin-bottom: var(--spacers-dp24);\n    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);\n    padding: var(--spacers-dp16);\n}\n\n.header {\n    margin-bottom: var(--spacers-dp8);\n}\n\n.name {\n    display: inline-block;\n    font-size: 16px;\n    font-weight: 500;\n    margin: 0;\n    margin-right: var(--spacers-dp8);\n}\n\n.email {\n    color: var(--colors-grey800);\n}\n\n.removeButton {\n    margin-left: var(--spacers-dp8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "seXSJk_1kWYVZ4eAnISL",
	"header": "gbDXqyDMyHWmo8j2w6f4",
	"name": "_5yVceQNohxZjqVok_em",
	"email": "bbN0HIE0_vdU3Pm4XNTF",
	"removeButton": "_3d7ANDGo9QPzxCQN1H_"
};
export default ___CSS_LOADER_EXPORT___;
