// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/breakpoints.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ceb_4_Zu7iyN67KG3SI_ {\n    margin: 2rem 0;\n    font-size: 1.8rem;\n    text-align: center;\n}\n\n.oyb87quC04MXigGcb0Ki {\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-gap: var(--spacers-dp16);\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["m-medium"] + " {\n    .oyb87quC04MXigGcb0Ki {\n        grid-template-columns: 1fr 1fr;\n        max-width: 992px;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Apps/AppCards/AppCards.module.css"],"names":[],"mappings":"AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,0BAA0B;IAC1B,6BAA6B;AACjC;;AAEA;IACI;QACI,8BAA8B;QAC9B,gBAAgB;IACpB;AACJ","sourcesContent":["@value m-medium from 'src/styles/breakpoints.css';\n\n.emptyApps {\n    margin: 2rem 0;\n    font-size: 1.8rem;\n    text-align: center;\n}\n\n.appCards {\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-gap: var(--spacers-dp16);\n}\n\n@media m-medium {\n    .appCards {\n        grid-template-columns: 1fr 1fr;\n        max-width: 992px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"m-medium": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["m-medium"] + "",
	"emptyApps": "Ceb_4_Zu7iyN67KG3SI_",
	"appCards": "oyb87quC04MXigGcb0Ki"
};
export default ___CSS_LOADER_EXPORT___;
